import React from 'react';
import { ReportStatGroup } from './report-stat-group';
import { ReportStatItem } from './report-stat-item';
import { Stat } from '../../api/types/cash-register/cash-point-closing.types';
import { useT } from '../../utils/helper';

interface ReportStatProp {
  title: string;
  stat: Stat;
  children?: React.ReactNode;
}

export const ReportStat = (props: ReportStatProp) => {
  const t = useT('screens', { keyPrefix: 'CashPointClosing' });

  return (
    <ReportStatGroup title={props.title}>
      <ReportStatItem title={t('totals')} value={props.stat.totals} />
      {props.children}
    </ReportStatGroup>
  );
};
