import request from '../../../request';
import { useMutation } from '@tanstack/react-query';
import {
  CreateCashPointClosingParams,
  CreateCashPointClosingResponse,
  PrintCashPointClosingParams,
} from '../../../types/cash-register/cash-point-closing.types';
import { PrintReceiptResponse } from '../../../types/vendor/carts-types';

export const useCashPointClosingMutations = () => {
  const createCashPointClosing = useMutation(
    async (params: CreateCashPointClosingParams) =>
      request({
        method: 'post',
        url: '/store/cash_point_closings.json',
        params: params,
      }) as Promise<CreateCashPointClosingResponse>,
  );

  const printCashPointClosing = useMutation(
    async ({ id, ...params }: PrintCashPointClosingParams) =>
      request({
        method: 'post',
        url: `/store/cash_point_closings/${id}/print.json`,
        params: params,
      }) as Promise<PrintReceiptResponse>,
  );

  return { createCashPointClosing, printCashPointClosing };
};
