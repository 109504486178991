import request from '../../../request';
import { useQuery } from '@tanstack/react-query';
import { CashPointClosingResponse } from '../../../types/cash-register/cash-point-closing.types';

export const useCashPointClosingQueries = (id: number) => {
  const fetchCashPointClosing = useQuery(
    ['cashPointClosings', id],
    async () =>
      request({
        method: 'get',
        url: `/store/cash_point_closings/${id}.json`,
      }) as Promise<CashPointClosingResponse>,
  );

  return { fetchCashPointClosing };
};
