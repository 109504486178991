import request from '../../../request';
import { useQuery } from '@tanstack/react-query';
import { NewCashPointClosingResponse } from '../../../types/cash-register/cash-point-closing.types';

export const useCashPointClosingNewQuery = () => {
  const fetchNewCashPointClosing = useQuery(
    ['cashPointClosings', 'new'],
    async () =>
      request({
        method: 'get',
        url: '/store/cash_point_closings/new.json',
      }) as Promise<NewCashPointClosingResponse>,
  );

  return { fetchNewCashPointClosing };
};
