import React from 'react';
import {
  CartTypeStats as CartTypeStatsType,
  TaxRate,
} from '../../api/types/cash-register/cash-point-closing.types';
import { ReportStatItem } from './report-stat-item';
import { toPercentage, useT } from '../../utils/helper';

interface CartTypeStatsProps {
  stats: CartTypeStatsType;
}

/**
 * CartTypeStats Component
 * Displays statistics for different cart types (delivery, pickup, table_order).
 * Each cart type's totals are mapped to a ReportStatItem component.
 *
 * @param {CartTypeStatsProps} props - The props containing stats for each cart type.
 * @returns {JSX.Element} - A fragment containing ReportStatItem components for each cart type.
 */
export const CartTypeStats = (props: CartTypeStatsProps) => {
  // Translation hooks for localized strings
  const t = useT('screens', { keyPrefix: 'CashPointClosing' });
  const commonT = useT();

  /**
   * Helper function to render ReportStatItem for a given cart type.
   *
   * @param {TaxRate} totals - The totals for a specific cart type.
   * @param {string} cartType - The type of cart (e.g., 'delivery', 'pickup', 'table_order').
   * @returns {JSX.Element[]} - An array of ReportStatItem components.
   */
  const renderCartTypeStats = (totals: TaxRate, cartType: string) => {
    return Object.keys(totals).map((item, index) => (
      <ReportStatItem
        key={index}
        title={t('totalsPerCartType', {
          cartType: commonT(cartType), // Localized cart type name
          tax: toPercentage(parseFloat(item)), // Convert tax key to percentage
        })}
        value={totals[item]} // Display the total value
      />
    ));
  };

  return (
    <>
      {/* Render stats for delivery cart type */}
      {renderCartTypeStats(props.stats.delivery.totals, 'delivery')}

      {/* Render stats for pickup cart type */}
      {renderCartTypeStats(props.stats.pickup.totals, 'pickup')}

      {/* Render stats for table_order cart type */}
      {renderCartTypeStats(props.stats.table_order.totals, 'table_order')}
    </>
  );
};
