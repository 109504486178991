import React from 'react';
import { ReportStat } from './report-stat';
import {
  StatCategory,
  StatCategoryDetail,
} from '../../api/types/cash-register/cash-point-closing.types';
import { toCamelCase, useT, hasEntries } from '../../utils/helper';
import { ReportStatItem } from './report-stat-item';

interface ReportStatCategoryDetailProps {
  statsCategory: StatCategoryDetail;
  category: StatCategory;
  returnedStats?: StatCategoryDetail;
}

export const ReportStatCategoryDetail = (
  props: ReportStatCategoryDetailProps,
) => {
  const t = useT('screens', { keyPrefix: 'CashPointClosing' });

  return Object.keys(props.statsCategory).map(key =>
    hasEntries(props.statsCategory[key]) ? (
      <ReportStat
        key={key}
        stat={props.statsCategory[key]}
        title={t(`${props.category}.${toCamelCase(key)}Transaction`)}>
        {props.returnedStats && props.returnedStats[key] ? (
          <ReportStatItem
            title={t('returnedItem')}
            value={props.returnedStats[key].totals}
          />
        ) : null}
      </ReportStat>
    ) : null,
  );
};
