import request from '../../../request';
import { useQuery } from '@tanstack/react-query';
import { NewCashierReportResponse } from '../../../types/cash-register/cashier-report.types';

export const useCashierReportNewQuery = () => {
  const fetchNewCashierReport = useQuery(
    ['cashierReports', 'new'],
    async () =>
      request({
        method: 'get',
        url: '/store/cashier_reports/new.json',
      }) as Promise<NewCashierReportResponse>,
    {
      initialData: {
        started_at: new Date().toISOString(),
        ended_at: new Date().toISOString(),
      },
    },
  );

  return { fetchNewCashierReport };
};
