import React from 'react';
import { ScreenContainer } from '../../../components/cash-register/shared/screen-container';
import { View } from 'react-native';
import { tw } from '../../../utils/tailwind';
import { PointClosingStatSummary } from '../../../components/cash-register/shared/point-closing-stat-summary';
import QuerySuspense from '../../../api/query-suspense';
import { RouteProp } from '@react-navigation/native';
import { AppStackParamList, AppStackScreenProps } from '../../../routes';
import { useCashierReportQueries } from '../../../api/hooks/cash-register/cashier-reports/use-cashier-report-queries';

interface CashierReportShowScreenProps {
  route: RouteProp<AppStackParamList, 'CashierReportShow'>;
  navigation: AppStackScreenProps<'CashPointClosing'>['navigation'];
}

export const CashierReportShowScreen = (
  props: CashierReportShowScreenProps,
) => {
  const { id } = props.route.params;
  const { fetchCashierReport } = useCashierReportQueries(id);

  return (
    <QuerySuspense
      query={fetchCashierReport}
      onSuccess={cashierReport => (
        <ScreenContainer
          title={cashierReport.title}
          containerStyle={tw`bg-white`}
          headerWrapperStyle={tw`w-full m-auto max-w-[800px] justify-between`}
          scrollable={true}
          onBackPress={() => props.navigation.navigate('CashierReportIndex')}>
          <View style={tw`w-full m-auto max-w-[800px]`}>
            <PointClosingStatSummary pointClosing={cashierReport} />
          </View>
        </ScreenContainer>
      )}
    />
  );
};
