import React from 'react';
import { FooterBar } from '../cash-register/footer-bar/footer-bar';
import { FooterBarItem } from '../cash-register/footer-bar/footer-bar-item';
import { useT } from '../../utils/helper';
import { useNavigation, useRoute } from '@react-navigation/native';
import { AppStackScreenProps } from '../../routes';
import { ViewStyle } from 'react-native';
import { tw } from '../../utils/tailwind';

interface ReportNavigationProps {}

export const ReportNavigation = (_props: ReportNavigationProps) => {
  const t = useT('screens', { keyPrefix: 'CashPointClosings' });
  const navigation =
    useNavigation<AppStackScreenProps<'CashPointClosing'>['navigation']>();
  const route = useRoute();

  // rendered
  const renderNavigation = (
    screenName: string,
    text: string,
    style?: ViewStyle,
  ) => {
    return (
      <FooterBarItem
        active={route.name === screenName}
        text={text}
        buttonStyles={[tw`md:px-3 md:py-4 min-w-[140px] px-2 py-4`, style]}
        textStyles={tw`md:text-lg text-base`}
        onPress={() => navigation.navigate(screenName as any)} // can't use keyof AppStackParamList
      />
    );
  };

  return (
    <FooterBar style={tw`gap-y-1`}>
      {renderNavigation(
        'CashPointClosings',
        t('cashPointClosing'),
        tw`rounded-l-lg`,
      )}
      {renderNavigation(
        'MonthReports',
        t('monthlyReport'),
        tw`md:rounded-r-none rounded-r-lg`,
      )}
      {renderNavigation(
        'YearReports',
        t('yearlyReport'),
        tw`md:rounded-l-none rounded-l-lg`,
      )}
      {renderNavigation(
        'CashierReportIndex',
        t('cashierReport'),
        tw`rounded-r-lg`,
      )}
    </FooterBar>
  );
};
