import React from 'react';
import { ReportStatGroup } from './report-stat-group';
import { ReportStatItem } from './report-stat-item';
import { useT } from '../../utils/helper';
import { Stat } from '../../api/types/cash-register/cash-point-closing.types';

interface ReportRevenueAfterCancellationProps {
  stat: Stat;
}

export const ReportRevenueAfterCancellation = (
  props: ReportRevenueAfterCancellationProps,
) => {
  const t = useT('screens', { keyPrefix: 'CashPointClosing' });

  return (
    <ReportStatGroup title={t('revenueAfterCancellation').toString()}>
      {['total_nets', 'taxes', 'totals'].map(key => (
        <ReportStatItem key={key} title={t(key)} value={props.stat[key]} />
      ))}
    </ReportStatGroup>
  );
};
