import React from 'react';
import { ReportStatGroup } from './report-stat-group';
import { ReportStatItem } from './report-stat-item';
import { Stat } from '../../api/types/cash-register/cash-point-closing.types';
import { useT } from '../../utils/helper';
import { CartTypeStats } from './cart-type-stats';

interface ReportStatProp {
  title: string;
  stat: Stat;
  children?: React.ReactNode;
}

export const ReportStat = (props: ReportStatProp) => {
  const t = useT('screens', { keyPrefix: 'CashPointClosing' });
  const commonT = useT();

  return (
    <ReportStatGroup title={props.title}>
      <ReportStatItem title={t('totals')} value={props.stat.totals} />

      {/* Display totals per payment provider */}
      {Object.entries(props.stat.payment_provider_stats || {}).map(
        ([key, providerStat]) =>
          providerStat.totals > 0 ? (
            <ReportStatItem
              key={key}
              title={t('totalsPerPaymentProvider', {
                paymentProvider: commonT(`paymentProviders.${key}`),
              })}
              value={providerStat.totals}
            />
          ) : null,
      )}

      {props.stat.cart_type_stats ? (
        <CartTypeStats stats={props.stat.cart_type_stats} />
      ) : null}

      {props.children}
    </ReportStatGroup>
  );
};
