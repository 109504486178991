import React from 'react';
import { Text } from 'react-native';
import { tw } from '../../../utils/tailwind';
import {
  ESTIMATION_PRINT_TIME,
  STANDARD_DATE_TIME_FORMAT,
  useT,
} from '../../../utils/helper';
import { format } from 'date-fns';
import { useNavigation } from '@react-navigation/native';
import { AppStackScreenProps } from '../../../routes';
import { useCashPointClosingMutations } from '../../../api/hooks/cash-register/cash-point-closings/use-cash-point-closing-mutations';
import { processPOSCommand } from '../../../utils/print-module';
import { useAlert } from '../../../contexs/alert-context';
import { CashPointClosingIndexItem } from '../../../api/types/cash-register/cash-point-closing.types';
import { ReportItem } from '../../../components/cash-register/reports/report-item';

interface CashPointClosingListItemProps {
  cashPointClosing: CashPointClosingIndexItem;
}

export const CashPointClosingListItem = (
  props: CashPointClosingListItemProps,
) => {
  // Hooks
  const t = useT('screens', { keyPrefix: 'CashPointClosings' });
  const navigation = useNavigation<AppStackScreenProps<'Root'>['navigation']>();
  const alert = useAlert();
  // States
  const [printing, setPrinting] = React.useState<boolean>(false);
  // Queries
  const { printCashPointClosing } = useCashPointClosingMutations();

  // handlers
  const handlePrint = () => {
    setPrinting(true);

    printCashPointClosing

      .mutateAsync({
        id: props.cashPointClosing.id,
        validate_printer_configuration: true,
      })
      .then(data => {
        if (data.print_jobs.length) {
          processPOSCommand(data.print_jobs);
        } else if (data.detail) {
          alert.setModalOptions({ contentWrapperClassNames: 'w-[600px]' });
          alert.show(data.detail);
        }
      })
      .finally(() => {
        // add delay since we don't have a proper way to know
        // when exactly the print is done
        setTimeout(() => {
          setPrinting(false);
        }, ESTIMATION_PRINT_TIME);
      });
  };

  return (
    <ReportItem
      onShow={() =>
        navigation.navigate('CashPointClosing', {
          id: props.cashPointClosing.id,
        })
      }
      onPrint={handlePrint}
      printDisabled={printing}>
      <Text
        style={tw`md:text-xl md:leading-none text-lg leading-none text-gray-800 font-medium`}>
        {t('createdAtDate', {
          date: format(
            props.cashPointClosing.created_at,
            STANDARD_DATE_TIME_FORMAT,
          ),
        })}
      </Text>
      <Text
        style={tw`md:text-lg md:leading-none text-base leading-none text-gray-800`}>
        {t('fromTime', {
          time: format(
            props.cashPointClosing.started_at,
            STANDARD_DATE_TIME_FORMAT,
          ),
        })}
      </Text>
      <Text
        style={tw`md:text-lg md:leading-none text-base leading-none text-gray-800`}>
        {t('toTime', {
          time: format(
            props.cashPointClosing.ended_at,
            STANDARD_DATE_TIME_FORMAT,
          ),
        })}
      </Text>
    </ReportItem>
  );
};
