import { Buffer } from '@craftzdog/react-native-buffer';
import { useTranslation } from 'react-i18next';
import * as Sentry from '@sentry/react-native';
import env from '../env';
import { Linking } from 'react-native';
import { i18namespaces } from '../i18n';

export const HEADER_HEIGHT = 64;
export const HEADER_SECTION_HEIGHT = HEADER_HEIGHT + 1;
export const STANDARD_DATE_FORMAT = 'MMM dd, yyyy';
export const STANDARD_DATE_TIME_FORMAT = 'dd.MM.yyyy HH:mm';
export const CURRENCY_SYMBOL = '€';

export const ESTIMATION_PRINT_TIME = 1000;

export const useT = (ns?: i18namespaces, options?: object) => {
  const { t } = useTranslation(ns, options);
  return t;
};

export const chunkArray = (arr: Array<any>, len: number) => {
  var chunks = [],
    i = 0,
    n = arr.length;

  while (i < n) {
    chunks.push(arr.slice(i, (i += len)));
  }

  return chunks;
};

// twCb is short for tailwind combine
// you can use it like that: twCb('text-lg', errorCondition ? 'text-red-500' : 'text-green-500')
export const twCb = (...classes: (string | undefined)[]) => {
  const res = classes.filter(entry => entry !== undefined);
  return res.join(' ');
};

export const splitLastArray = <T>(arr: Array<T>) => {
  return {
    allOptionsExceptTheLast: arr.slice(0, arr.length - 1),
    lastOption: arr[arr.length - 1],
  };
};

export function convertBase64ToHex(base64String: string) {
  const binaryString = Buffer.from(base64String, 'base64').toString('binary');
  let hexString = '';
  for (let i = 0; i < binaryString.length; i++) {
    const hex = binaryString.charCodeAt(i).toString(16);
    hexString += hex.length === 2 ? hex : '0' + hex;
  }
  return hexString;
}

export function convertHexToBase64(base64String: string) {
  const buffer = Buffer.from(base64String, 'hex');

  return buffer.toString('base64');
}

export function sentryCaptureMessage(message: string) {
  if (!env.SENTRY_DSN) {
    return;
  }

  Sentry.withScope(function (_scope) {
    Sentry.captureException(message);
  });
}

export const setLineBreaks = (text: string, maxChar: number) => {
  const words = text.split(' ');
  let totalChars = 0;

  words.forEach((word, i) => {
    const nextWord = words[i + 1] || '';
    totalChars += word.length;

    if (totalChars + nextWord.length >= maxChar) {
      words[i + 1] = '\n' + nextWord;
    }
  });

  return words.join(' ');
};

export const navigateToAdmin = async (
  store_app_type?: string,
  path?: string,
) => {
  const supported = await Linking.canOpenURL(env.APP_URL);
  const isNative = store_app_type === 'native';
  const url = `${env.APP_URL}${path ?? ''}`;

  // Linking doesn't work on ios
  if (['ios', 'web'].includes(window.injectedObject.platform)) {
    // Open in new tab with fallback to current tab
    window.location.href = url;
  } else if (supported || isNative) {
    // When the app is webview (and supported) or native, we can use Linking
    await Linking.openURL(url);
  } else {
    sentryCaptureMessage(`Don't know how to open this URL: ${url}`);
  }
};

export const formatCurrency = (amount: number): string => {
  return new Intl.NumberFormat('de-DE', {
    style: 'currency',
    currency: 'EUR',
  }).format(amount);
};

export const formatNumber = (
  amount: number,
  options?: { minimumFractionDigits?: number },
): string => {
  return amount.toLocaleString('de-DE', {
    minimumFractionDigits: options?.minimumFractionDigits ?? 2,
    maximumFractionDigits: 2,
  });
};

export const toCamelCase = (str: string): string => {
  return str.replace(/_([a-z])/g, (_match, letter) => letter.toUpperCase());
};

export const hasEntries = (obj: object) => {
  return Object.entries(obj).length > 0;
};

export const toPercentage = (value: number): string => {
  return `${formatNumber(value * 100, { minimumFractionDigits: 0 })}%`;
};

export const isTakeaway = (cartType: string) => {
  return ['pickup', 'delivery'].includes(cartType);
};

export const escapeRegExp = (string: string) => {
  return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // Escapes special characters
};
