import React from 'react';
import { ReportStatGroup } from './report-stat-group';
import { useT } from '../../utils/helper';
import { ReportStatItem } from './report-stat-item';
import { DayStats } from '../../api/types/cash-register/month-report.types';
import { MonthStats } from '../../api/types/cash-register/year-report.types';

interface ReportDayStatsProps {
  stats: DayStats | MonthStats;
}

export const ReportDayStats = (props: ReportDayStatsProps) => {
  const t = useT('screens', { keyPrefix: 'CashPointClosing' });
  return (
    <ReportStatGroup title={t('grossSalesPerDay').toString()}>
      {Object.entries(props.stats).map(([date, amount], index) => (
        <ReportStatItem key={index} title={date} value={amount} />
      ))}
    </ReportStatGroup>
  );
};
