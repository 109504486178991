import React, { useEffect, useRef } from 'react';
import { tw } from '../../../utils/tailwind';
import { View, useWindowDimensions } from 'react-native';
import { SelectList } from '../../forms/select-list';
import { DatePickerInput } from '../../forms/date-picker-input';
import QuerySuspense from '../../../api/query-suspense';
import { useStoreTableQueries } from '../../../api/hooks/cash-register/store-table/use-store-table-queries';
import { useTransactionFilterForm } from '../../../forms/use-transaction-filter-form.hook';
import { formatISO } from 'date-fns';
import { useT } from '../../../utils/helper';
import { InvoiceTypes } from '../../../utils/constants';
import { CashierPicker } from '../../forms/cashier-picker';
export interface FilterableAtttributes {
  store_table_id_eq?: number;
  cashier_id_eq?: number;
  date?: string;
  invoice_type_name_eq?: string;
}

interface TransactionFilterFormProps {
  initialValues: FilterableAtttributes;
  documentType: 'Invoice' | 'Order';
  includeStoreTableFilter: boolean;
  onChange: (values: FilterableAtttributes) => void;
}

export const TransactionFilterForm = (props: TransactionFilterFormProps) => {
  // Standard Hooks
  const t = useT();
  const { width } = useWindowDimensions();
  // Queries
  const { fetchStoreTables } = useStoreTableQueries({
    sort_by: 'table_number_nulls_last asc',
  });
  // Custom Hooks
  const form = useTransactionFilterForm();

  // Ref to track initial setting of values
  const isInitialSet = useRef(false);

  // Set initial values
  useEffect(() => {
    form.setValues(props.initialValues);
    isInitialSet.current = true;
  }, []);

  // Handle form value changes
  useEffect(() => {
    if (isInitialSet.current) {
      isInitialSet.current = false; // Reset the flag after initial change
    } else {
      props.onChange(form.values);
    }
  }, [form.values]);

  return (
    <View style={tw`flex-row flex-wrap mb-3 z-5 gap-y-3`}>
      {/* Table Filter */}
      {props.includeStoreTableFilter ? (
        <View style={[tw`md:w-1/3 w-1/2 pr-3 z-2`, { order: 1 }]}>
          <QuerySuspense
            query={fetchStoreTables}
            hideRefetchLoading={true}
            onSuccess={tables => (
              <SelectList
                setSelected={selectedOption =>
                  form.setFieldValue('store_table_id_eq', selectedOption?.key)
                }
                data={tables}
                defaultOption={props.initialValues?.store_table_id_eq}
                labelKey="name"
                valueKey="id"
                label={t('table').toString()}
                placeholder={t('chooseTable').toString()}
                optionSelectAlloption={t('allTables').toString()}
              />
            )}
          />
        </View>
      ) : null}
      {/* Cashier Filter */}
      <View
        style={[
          tw`md:w-1/3 md:grow-0 pr-3 grow w-1/2`,
          { order: width >= 768 ? 2 : 4 }, // change order on small screen,
        ]}>
        <CashierPicker
          setSelected={selectedOption =>
            form.setFieldValue('cashier_id_eq', selectedOption?.key)
          }
          defaultOption={props.initialValues?.cashier_id_eq}
        />
      </View>
      {/* Date Filter */}
      <View style={[tw`md:w-1/3 pr-3 w-1/2`, { order: 3 }]}>
        <DatePickerInput
          onChange={date =>
            form.setFieldValue(
              'date',
              date ? formatISO(date, { representation: 'date' }) : null,
            )
          }
          label={t('date').toString()}
          placeholder={t('chooseDate').toString()}
        />
      </View>
      {/* Invoice type Filter */}
      {props.documentType === 'Invoice' ? (
        <View style={[tw`md:w-1/3 pr-3 w-1/2`, { order: 5 }]}>
          <SelectList
            setSelected={selectedOption =>
              form.setFieldValue('invoice_type_name_eq', selectedOption?.key)
            }
            data={InvoiceTypes.map(type => ({ id: type, name: t(type) }))}
            defaultOption={props.initialValues?.invoice_type_name_eq}
            valueKey="id"
            labelKey="name"
            label={t('invoice').toString()}
            optionSelectAlloption={`- ${t('allInvoices').toString()} -`}
          />
        </View>
      ) : null}
    </View>
  );
};
