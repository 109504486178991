import React from 'react';
import { View, Text } from 'react-native';
import { CashbookNewEntryButton } from './cashbook-new-entry-button';
import { tw } from '../../../utils/tailwind';

interface CashbookEntriesEmptyProps {}

export const CashbookEntriesEmpty: React.FC<CashbookEntriesEmptyProps> = () => {
  return (
    <View style={tw`justify-center`}>
      <Text
        style={tw`mb-2 text-[28px] leading-snug font-bold text-gray-800 text-center`}>
        Los geht's
      </Text>
      <Text style={tw`mb-8 text-lg leading-snug text-gray500 text-center`}>
        Gebe jetzt deinen Startwert ein
      </Text>

      <View style={tw`self-center`}>
        <CashbookNewEntryButton responsive={false} />
      </View>
    </View>
  );
};
