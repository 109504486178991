import request from '../../../request';
import { useQuery } from '@tanstack/react-query';
import { YearReportResponse } from '../../../types/cash-register/year-report.types';

export const useYearReportQueries = (id: number) => {
  const fetchYearReport = useQuery(
    ['yearReports', id],
    async () =>
      request({
        method: 'get',
        url: `/store/year_reports/${id}.json`,
      }) as Promise<YearReportResponse>,
  );

  return { fetchYearReport };
};
