import React from 'react';
import { Text } from 'react-native';
import { tw } from '../../../utils/tailwind';
import {
  ESTIMATION_PRINT_TIME,
  STANDARD_DATE_TIME_FORMAT,
  useT,
} from '../../../utils/helper';
import { format } from 'date-fns';
import { useNavigation } from '@react-navigation/native';
import { AppStackScreenProps } from '../../../routes';
import { processPOSCommand } from '../../../utils/print-module';
import { useAlert } from '../../../contexs/alert-context';
import { useYearReportMutations } from '../../../api/hooks/cash-register/year-reports/use-year-report-mutations';
import { YearReportIndexItem } from '../../../api/types/cash-register/year-report.types';
import { ReportItem } from '../../../components/cash-register/reports/report-item';

interface YearReportListItemProps {
  yearReport: YearReportIndexItem;
}

export const YearReportListItem = (props: YearReportListItemProps) => {
  // Hooks
  const t = useT('screens', { keyPrefix: 'YearReports' });
  const navigation = useNavigation<AppStackScreenProps<'Root'>['navigation']>();
  const alert = useAlert();
  // States
  const [printing, setPrinting] = React.useState<boolean>(false);
  // Queries
  const { printYearReport } = useYearReportMutations();

  // handlers
  const handlePrint = () => {
    setPrinting(true);

    printYearReport
      .mutateAsync({
        id: props.yearReport.id,
        validate_printer_configuration: true,
      })
      .then(data => {
        if (data.print_jobs.length) {
          processPOSCommand(data.print_jobs);
        } else if (data.detail) {
          alert.setModalOptions({ contentWrapperClassNames: 'w-[600px]' });
          alert.show(data.detail);
        }
      })
      .finally(() => {
        // add delay since we don't have a proper way to know
        // when exactly the print is done
        setTimeout(() => {
          setPrinting(false);
        }, ESTIMATION_PRINT_TIME);
      });
  };

  return (
    <ReportItem
      onShow={() =>
        navigation.navigate('YearReport', {
          id: props.yearReport.id,
        })
      }
      onPrint={handlePrint}
      printDisabled={printing}>
      <Text
        style={tw`md:text-xl md:leading-none text-lg leading-none text-gray-800 font-medium`}>
        {t('createdAtDate', {
          date: format(props.yearReport.created_at, STANDARD_DATE_TIME_FORMAT),
        })}
      </Text>
      <Text
        style={tw`md:text-lg md:leading-none text-base leading-none text-gray-800`}>
        {t('year', {
          year: props.yearReport.year_name,
        })}
      </Text>
    </ReportItem>
  );
};
