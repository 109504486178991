import { useEffect, useState } from 'react';
import { useWindowDimensions } from 'react-native';
import { HEADER_SECTION_HEIGHT } from '../utils/helper';
import { useTenant } from '../contexs/tenant-context';

export const useWindowSize = () => {
  const { height, width } = useWindowDimensions();
  const [windowSize, setWindowSize] = useState<string>(getWindowSize(width));
  const { tenant } = useTenant();

  useEffect(() => {
    setWindowSize(getWindowSize(width));
  }, [width]);

  return {
    windowSize,
    height,
    width,
    isLg: ['lg', 'xl'].includes(windowSize),
    headerHeight: tenant.vendor_cash_point_closing_enabled
      ? HEADER_SECTION_HEIGHT
      : getCashRegisterHeaderHeight(width),
    wrapperPadding: width > 708 ? 40 : 16,
  };
};

const getWindowSize = (width: number) => {
  if (width < 450) {
    return 'xs';
  } else if (width < 640) {
    return 'sm';
  } else if (width < 768) {
    return 'md';
  } else if (width < 1024) {
    return 'lg';
  } else {
    return 'xl';
  }
};

const getCashRegisterHeaderHeight = (width: number) => {
  // header only shows when width is greater than 1440
  return width < 1440 ? 0 : HEADER_SECTION_HEIGHT;
};
