import React, { useRef, useState } from 'react';
import { useT } from '../../../utils/helper';
import { FooterBar } from '../../../components/cash-register/footer-bar/footer-bar';
import { FooterBarItem } from '../../../components/cash-register/footer-bar/footer-bar-item';
import { useAuth } from '../../../contexs/auth-context';
import { useCashierReportMutations } from '../../../api/hooks/cash-register/cashier-reports/use-cashier-report-mutations';
import { useAlert } from '../../../contexs/alert-context';
import { WorkflowListener } from '../../../components/workflow-listener';
import { CashierReportList } from './cashier-report-list';
import { CashierPicker } from '../../../components/forms/cashier-picker';
import { ModalDateRangeInput } from '../../../components/cash-register/modals/modal-date-range-input';
import { useCashierReportNewQuery } from '../../../api/hooks/cash-register/cashier-reports/use-cashier-report-new-query';
import { ReportIndexScreen } from '../../../components/cash-register/shared/report-index-screen';

interface PendingWorkflow {
  id: number;
  message: string;
}

enum ModalVisible {
  CreateCashierReport = 'createCashierReport',
  None = 'none',
}

export const CashierReportIndexScreen = () => {
  // Standard Hooks
  const t = useT('screens', { keyPrefix: 'CashierReportIndex' });
  // Queries
  const { fetchNewCashierReport } = useCashierReportNewQuery();
  const { started_at, ended_at } = fetchNewCashierReport.data;
  // Custom Hooks
  const alert = useAlert();
  const { currentCashier } = useAuth();
  const { createCashierReport } = useCashierReportMutations();
  const pwId = useRef<number | null>(null);
  // States
  const [modalVisible, setModalVisible] = useState<ModalVisible>();
  const [cashierId, setCashierId] = useState<number>();
  const [pendingWorkflow, setPendingWorkflow] =
    useState<PendingWorkflow | null>(null);

  // Handlers
  const handleAddNewCashierReport = (startDate: Date, endDate: Date) => {
    if (!currentCashier) {
      return;
    }

    setModalVisible(ModalVisible.None);

    createCashierReport
      .mutateAsync({
        cashier_id: currentCashier.id,
        started_at: startDate,
        ended_at: endDate,
      })
      .then(data => {
        pwId.current = data.workflow.id;
        setPendingWorkflow({
          id: data.workflow.id,
          message: data.message,
        });
      })
      .catch(response => {
        alert.show(response.data);
      });
  };

  const handleWebSocketMessage = (message: any) => {
    if (message.id !== pwId.current) {
      return;
    }

    if (message.status === 'failed' && message.workflow_error_messages) {
      alert.show(message.workflow_error_messages.join('\n'));
    }

    setPendingWorkflow(null);
  };

  return (
    <>
      <ReportIndexScreen
        title={t('pageTitle')}
        filterView={
          <CashierPicker
            setSelected={selectedOption => setCashierId(selectedOption?.key)}
          />
        }
        listView={<CashierReportList cashierId={cashierId} />}
        worfklowPendingView={
          <WorkflowListener
            loadingMessage={pendingWorkflow?.message}
            channel="CreateCashierReportWorkflow"
            onMessage={handleWebSocketMessage}
          />
        }
        workflowPending={!!pendingWorkflow}
      />

      <FooterBar>
        <FooterBarItem
          active={true}
          disabled={!!pendingWorkflow || fetchNewCashierReport.isLoading}
          text={t('addNewCashierReport')}
          onPress={() => setModalVisible(ModalVisible.CreateCashierReport)}
        />
      </FooterBar>

      {/* Modals */}
      {modalVisible === ModalVisible.CreateCashierReport ? (
        <ModalDateRangeInput
          isVisible={modalVisible === ModalVisible.CreateCashierReport}
          onModalHide={() => setModalVisible(ModalVisible.None)}
          onDateRangeSelected={handleAddNewCashierReport}
          initialStartDate={started_at ? new Date(started_at) : null}
          initialEndDate={ended_at ? new Date(ended_at) : null}
        />
      ) : null}
    </>
  );
};
