import request from '../../../request';
import { useMutation } from '@tanstack/react-query';
import { PrintReceiptResponse } from '../../../types/vendor/carts-types';
import {
  CreateCashierReportParams,
  CreateCashierReportResponse,
  PrintCashierReportParams,
} from '../../../types/cash-register/cashier-report.types';

export const useCashierReportMutations = () => {
  const createCashierReport = useMutation(
    async (params: CreateCashierReportParams) =>
      request({
        method: 'post',
        url: '/store/cashier_reports.json',
        params: { cashier_report: params },
      }) as Promise<CreateCashierReportResponse>,
  );

  const printCashierReport = useMutation(
    async ({ id, ...params }: PrintCashierReportParams) =>
      request({
        method: 'post',
        url: `/store/cashier_reports/${id}/print.json`,
        params: params,
      }) as Promise<PrintReceiptResponse>,
  );

  return { createCashierReport, printCashierReport };
};
