import request from '../../../request';
import { useInfiniteQuery } from '@tanstack/react-query';
import {
  MonthReportsParams,
  MonthReportsResponse,
} from '../../../types/cash-register/month-report.types';

export const useMonthReportsQueries = (params: MonthReportsParams) => {
  const fetchMonthReports = useInfiniteQuery(
    ['monthReports', params],
    async ({ pageParam }) =>
      request({
        method: 'get',
        url: '/store/month_reports.json',
        params: { ...params, page: pageParam },
      }) as Promise<MonthReportsResponse>,
    {
      getNextPageParam: (lastPage, _pages) => {
        // Return the next page number if there are more pages
        return lastPage.next_page || false;
      },
    },
  );

  return { fetchMonthReports };
};
