import React from 'react';
import * as Sentry from '@sentry/react-native';
import { MenuProvider } from 'react-native-popup-menu';
import { SafeAreaProvider } from 'react-native-safe-area-context';
import useOnlineStatusManagement from './src/hooks/use-online-status-management';
import MainNavigator from './src/navigation/main-navigator';
import AuthProvider from './src/providers/auth-provider';
import QueryClientProvider from './src/providers/query-client-provider';
import { useDeviceContext } from 'twrnc';
import { tw } from './src/utils/tailwind';
import './src/i18n';
import 'air-datepicker/air-datepicker.css';
import { HEADER_HEIGHT } from './src/utils/helper';
import { WebViewConnector } from './src/components/web/webview-connector';
import { AlertProvider } from './src/providers/alert-provider';
import env from './src/env';

function App(): JSX.Element {
  // to auto refetch on reconnect
  useOnlineStatusManagement();
  useDeviceContext(tw);

  return (
    <SafeAreaProvider>
      <AuthProvider>
        <QueryClientProvider>
          <WebViewConnector />

          <MenuProvider
            customStyles={{
              backdrop: tw.style(`mt-[${HEADER_HEIGHT}px] bg-black opacity-70`),
            }}>
            <AlertProvider>
              <MainNavigator />
            </AlertProvider>
          </MenuProvider>
        </QueryClientProvider>
      </AuthProvider>
    </SafeAreaProvider>
  );
}

export default env.SENTRY_DSN ? Sentry.withProfiler(App) : App;
