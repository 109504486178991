import React from 'react';
import { View, Text } from 'react-native';
import useWorkflowChannel from '../websocket/hooks/use-workflow-channel';
import SplashScreen from '../screens/splash/splash.screen';
import { tw } from '../utils/tailwind';

export interface WorkflowListenerProps {
  channel: string;
  loadingMessage?: string;
  onMessage: (message: any) => void;
}

export const WorkflowListener = (props: WorkflowListenerProps) => {
  useWorkflowChannel(props.channel, props.onMessage);

  if (!props.loadingMessage) {
    return null;
  }

  return (
    <View style={tw`gap-y-5 min-h-[100%]`}>
      {/* loading spinner */}
      <SplashScreen style={tw`relative`} />

      <View>
        <Text style={tw`text-center text-xl`}>{props.loadingMessage}</Text>
      </View>

      <WorkflowListener channel={props.channel} onMessage={props.onMessage} />
    </View>
  );
};
