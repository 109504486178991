import React, { useState } from 'react';
import { useT } from '../../../utils/helper';
import { useAlert } from '../../../contexs/alert-context';
import { useAuth } from '../../../contexs/auth-context';
import { DateFilter } from '../../../components/filters/date-filter';
import { useYearReportMutations } from '../../../api/hooks/cash-register/year-reports/use-year-report-mutations';
import { YearReportList } from './year-report-list';
import { ReportActionBar } from '../../../components/cash-register/reports/report-action-bar';
import { format } from 'date-fns';
import { WorkflowListener } from '../../../components/workflow-listener';
import { ReportIndexScreen } from '../../../components/cash-register/shared/report-index-screen';

interface PendingWorkflow {
  message: string;
}

export const YearReportListScreen = () => {
  // Standard Hooks
  const t = useT('screens', { keyPrefix: 'YearReports' });
  // Custom Hooks
  const alert = useAlert();
  const { currentCashier } = useAuth();
  // States
  const [date, setDate] = useState<Date | null>(null);
  const [pendingWorkflow, setPendingWorkflow] =
    useState<PendingWorkflow | null>(null);
  // Queries
  const { createYearReport } = useYearReportMutations();

  // Handlers
  const handleAddNewYearReport = async () => {
    const year = new Date().getFullYear();

    if (!currentCashier) {
      return;
    }

    createYearReport
      .mutateAsync({ cashier_id: currentCashier.id, year })
      .then(data => {
        setPendingWorkflow(data);
      })
      .catch(response => {
        alert.show(response.data);
      });
  };

  const handleWebSocketMessage = (message: any) => {
    if (message.cashier_id !== currentCashier?.id) {
      return;
    }

    setPendingWorkflow(null);
  };

  return (
    <>
      <ReportIndexScreen
        title={t('pageTitle')}
        filterView={
          <DateFilter
            view="years"
            onDateChange={selectedDate => setDate(selectedDate)}
          />
        }
        listView={<YearReportList date={date} />}
        worfklowPendingView={
          <WorkflowListener
            loadingMessage={pendingWorkflow?.message}
            channel="create_year_report"
            onMessage={handleWebSocketMessage}
          />
        }
        workflowPending={!!pendingWorkflow}
      />

      <ReportActionBar
        disabled={createYearReport.isLoading}
        confirmationMessage={t('createConfirmationMessage', {
          year: format(new Date(), 'yyyy'),
        })}
        createButtonText={t('addNewYearReport')}
        onCreate={handleAddNewYearReport}
      />
    </>
  );
};
