import React from 'react';
import { TouchableOpacity, Text } from 'react-native';
import { tw } from '../../../utils/tailwind';

interface ProductTileProps {
  text: string;
  mode: 'default' | 'embed';
  onPress: () => void;
}

export const ProductTile = (props: ProductTileProps) => {
  return (
    <TouchableOpacity
      onPress={props.onPress}
      style={[
        tw`justify-center items-center p-4 border border-solid border-gray-200 rounded-lg bg-gray-100 h-[120px]`,
        props.mode === 'embed'
          ? tw`md:basis-[23%] basis-[47%] grow`
          : tw`basis-[23%]`,
      ]}>
      <Text
        style={tw`text-lg text-center leading-none text-gray-800 max-w-full`}>
        {props.text}
      </Text>
    </TouchableOpacity>
  );
};
