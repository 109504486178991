import request from '../../../request';
import { useMutation } from '@tanstack/react-query';
import {
  CreateMonthReportParams,
  CreateMonthReportResponse,
  PrintMonthReportParams,
} from '../../../types/cash-register/month-report.types';
import { PrintReceiptResponse } from '../../../types/vendor/carts-types';

export const useMonthReportMutations = () => {
  const createMonthReport = useMutation(
    async (params: CreateMonthReportParams) =>
      request({
        method: 'post',
        url: '/store/month_reports.json',
        params: { month_report: params },
      }) as Promise<CreateMonthReportResponse>,
  );

  const printMonthReport = useMutation(
    async ({ id, ...params }: PrintMonthReportParams) =>
      request({
        method: 'post',
        url: `/store/month_reports/${id}/print.json`,
        params: params,
      }) as Promise<PrintReceiptResponse>,
  );

  return { createMonthReport, printMonthReport };
};
