import React, { useState } from 'react';
import { T0TouchableOpacity as Button } from '../../../components/forms/overrides';
import { View, useWindowDimensions } from 'react-native';
import { tw } from '../../../utils/tailwind';
import { useInvoice } from '../../../contexs/invoice-context';
import { Invoice } from '../../../api/types/cash-register/invoice.types';
import { useT } from '../../../utils/helper';
import { useNavigation } from '@react-navigation/native';
import { AppStackScreenProps } from '../../../routes';
import { useInvoiceMutations } from '../../../api/hooks/cash-register/invoices/use-invoice-mutation';
import { processPOSCommand } from '../../../utils/print-module';
import { useAlert } from '../../../contexs/alert-context';

interface InvoiceSummaryActionButtonsProps {
  invoice: Invoice;
  onCancel: () => void;
}

export const InvoiceSummaryActionButtons = (
  props: InvoiceSummaryActionButtonsProps,
) => {
  // Standard Hooks
  const t = useT();
  const alert = useAlert();
  const { width } = useWindowDimensions();
  const navigation =
    useNavigation<AppStackScreenProps<'Invoices'>['navigation']>();
  // states
  const [isPrinting, setIsPrinting] = useState(true);
  // Custom Hooks
  const { setInvoice } = useInvoice();
  const { printInvoice } = useInvoiceMutations();
  // Callbacks
  const handleCancel = () => {
    setInvoice(props.invoice);
    props.onCancel();
  };

  const handlePrint = () => {
    setIsPrinting(true);

    printInvoice
      .mutateAsync({
        id: props.invoice.id,
        document: 'RECEIPT',
        validate_printer_configuration: true,
      })
      .then(data => {
        if (data.print_jobs.length) {
          processPOSCommand(data.print_jobs);
        } else if (data.detail) {
          alert.setModalOptions({ contentWrapperClassNames: 'w-[600px]' });
          alert.show(data.detail);
        }
      })
      .finally(() => {
        // add 1 second delay since we don't have a proper way to know
        // when exactly the print is done
        setTimeout(() => {
          setIsPrinting(false);
        }, 1000);
      });
  };

  return (
    <View style={tw`flex-row flex-wrap justify-between`}>
      <View
        style={[tw`md:basis-1/3 basis-1/1`, { order: width >= 768 ? 1 : 4 }]}>
        <Button
          theme={props.invoice.cancelable ? 'red' : 'white'}
          disabled={!props.invoice.cancelable}
          label={t('cancel')}
          extraStyle="py-5 px-3"
          extraLabelStyle="leading-none"
          onPress={handleCancel}
        />
      </View>
      <View
        style={[
          tw`md:basis-1/3 md:px-3 md:pb-0 pr-3 pb-3 basis-1/2`,
          { order: 2 },
        ]}>
        <Button
          label={t('qrReceipt')}
          extraStyle="py-5 px-3"
          extraLabelStyle="leading-none"
          onPress={() =>
            navigation.navigate('InvoiceShowQr', { id: props.invoice.id })
          }
        />
      </View>
      <View style={[tw`md:basis-1/3 basis-1/2`, { order: 3 }]}>
        <Button
          theme="green"
          label={t('printReceipt')}
          extraStyle="py-5 px-3"
          extraLabelStyle="leading-none text-center"
          disableOnPress={isPrinting}
          onPress={handlePrint}
        />
      </View>
    </View>
  );
};
