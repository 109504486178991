import React from 'react';
import { TouchableOpacity, View } from 'react-native';
import { tw } from '../../../utils/tailwind';
import { EyeSvg, PrintSVG } from '../../../../assets/svg';
import { LoadingAnimation } from '../../loading-animation';

interface ReportItemProps {
  onShow: () => void;
  onPrint: () => void;
  printDisabled: boolean;
  children: React.ReactNode;
}

export const ReportItem: React.FC<ReportItemProps> = props => {
  return (
    <View
      style={tw`flex-row flex-wrap gap-x-4 justify-between items-center border-b-[1px] border-gray-200 pb-1.5`}>
      <View style={tw`gap-y-1.5`}>{props.children}</View>

      <View
        style={tw`flex-row flex-wrap gap-y-1 xs:w-auto xs:gap-x-3 xs:mt-0 -mt-3 w-full gap-x-2 justify-end`}>
        <TouchableOpacity
          onPress={props.onShow}
          style={tw`p-2.5 bg-gray-100 justify-center items-center rounded-lg shadow-sm`}>
          <EyeSvg style={tw`h-7 w-7`} stroke={tw.color('gray-600')} />
        </TouchableOpacity>

        <TouchableOpacity
          disabled={props.printDisabled}
          onPress={props.onPrint}
          style={tw`p-2.5 bg-gray-100 justify-center items-center rounded-lg shadow-sm`}>
          {props.printDisabled ? (
            <LoadingAnimation
              color={tw.color('gray-800')}
              contentWrapperStyle={tw`h-7 w-7`}
            />
          ) : (
            <PrintSVG style={tw`h-7 w-7`} stroke={tw.color('gray-600')} />
          )}
        </TouchableOpacity>
      </View>
    </View>
  );
};
