import React from 'react';
import QuerySuspense from '../../api/query-suspense';
import { useCashiersQueries } from '../../api/hooks/cash-register/cashiers/use-cashiers-queries';
import { SelectList } from './select-list';
import { useT } from '../../utils/helper';
import { SelectListProps } from './select-list-props';

type CashierPickerProps<T> = Pick<
  SelectListProps<T>,
  'setSelected' | 'defaultOption'
>;

export const CashierPicker = <T extends any>(props: CashierPickerProps<T>) => {
  const t = useT();
  const { fetchCashiers } = useCashiersQueries();

  return (
    <QuerySuspense
      query={fetchCashiers}
      hideRefetchLoading={true}
      onSuccess={cashiers => (
        <SelectList
          setSelected={props.setSelected}
          defaultOption={props.defaultOption}
          data={cashiers}
          valueKey="id"
          labelKey="name"
          label={t('cashier').toString()}
          placeholder={t('chooseCashier').toString()}
          optionSelectAlloption={t('allCashiers').toString()}
        />
      )}
    />
  );
};
