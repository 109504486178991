import './instrument';
import { AppRegistry } from 'react-native';
import { name as appName } from './app.json';
import { patchFlatListProps } from 'react-native-web-refresh-control';
import App from './App';

if (module.hot) {
  module.hot.accept();
}

patchFlatListProps();

AppRegistry.registerComponent(appName, () => App);
AppRegistry.runApplication(appName, {
  initialProps: {},
  rootTag: document.getElementById('app-root'),
});

// Webview will inject an object called "injectedObject" into the window object.
window.injectedObject = {
  platform: 'web',
};

window.version = '1.0.0';
