import React from 'react';
import { View, Text } from 'react-native';
import { tw } from '../../utils/tailwind';
import { formatCurrency } from '../../utils/helper';

interface ReportStatItemProps {
  title: string;
  value: number;
  formatValue?: boolean;
}

export const ReportStatItem = (props: ReportStatItemProps) => {
  return (
    <View style={tw`flex-row justify-between`}>
      <Text style={tw`text-base`}>{props.title}</Text>
      <Text style={tw`text-base`}>
        {props.formatValue ? formatCurrency(props.value) : props.value}
      </Text>
    </View>
  );
};

ReportStatItem.defaultProps = {
  formatValue: true,
};
