import React, { useEffect } from 'react';
import { ScreenContainer } from '../../../components/cash-register/shared/screen-container';
import { tw } from '../../../utils/tailwind';
import { RouteProp } from '@react-navigation/native';
import { AppStackParamList, AppStackScreenProps } from '../../../routes';
import { useCashRegisterInvoiceWorkflowQueries } from '../../../api/hooks/cash-register/cash-register-workflows/use-cash-register-invoice-workflow-queries';
import { InvoicePaymentButtons } from './invoice-payment-buttons';
import { PaymentProvider } from '../../../providers/payment-provider';
import SplashScreen from '../../splash/splash.screen';
import { WorkflowListener } from '../../../components/workflow-listener';
import { useAuth } from '../../../contexs/auth-context';

interface InvoiceWorkflowPendingScreenProps {
  route: RouteProp<AppStackParamList, 'InvoiceWorkflowPending'>;
  navigation: AppStackScreenProps<'InvoiceWorkflowPending'>['navigation'];
}

export const InvoiceWorkflowPendingScreen = (
  props: InvoiceWorkflowPendingScreenProps,
) => {
  const workflowId = props.route.params.id;
  const { currentCashier } = useAuth();

  // Queries
  const { fetchCashRegisterInvoiceWorkflow } =
    useCashRegisterInvoiceWorkflowQueries({ id: workflowId });

  // Callbacks
  const checInvoiceCreated = (invoice_id?: number) => {
    if (invoice_id) {
      props.navigation.replace('InvoiceShow', {
        id: invoice_id,
      });
    }
  };

  // Effects
  useEffect(() => {
    checInvoiceCreated(fetchCashRegisterInvoiceWorkflow.data?.invoice_id);
  }, [fetchCashRegisterInvoiceWorkflow.data?.invoice_id]);

  return (
    <>
      <WorkflowListener
        channel="CashRegisterInvoiceWorkflow"
        onMessage={(message: any) => {
          if (message.cashier_id === currentCashier?.id && message.invoice_id) {
            checInvoiceCreated(message.invoice_id);
          }
        }}
      />

      <ScreenContainer
        title=""
        scrollable={true}
        headerWrapperStyle={tw`opacity-0`}
        containerStyle={tw`my-auto`}>
        <SplashScreen />
      </ScreenContainer>

      {/* Action bar */}
      <PaymentProvider initialStep="PAYMENT">
        <InvoicePaymentButtons />
      </PaymentProvider>
    </>
  );
};
