import React from 'react';
import { FooterBar } from '../footer-bar/footer-bar';
import { FooterBarItem } from '../footer-bar/footer-bar-item';
import { ModalConfirmation } from '../../shared/modals/modal-confirmation';

export const REPORT_ACTION_BAR_HEIGHT = 66;

enum ModalVisible {
  NONE,
  CONFIRMATION,
}

interface ReportActionBarProps {
  disabled: boolean;
  createButtonText: string;
  confirmationMessage: string;
  onCreate: () => void;
}

export const ReportActionBar = (props: ReportActionBarProps) => {
  const [modalVisible, setModalVisible] = React.useState(ModalVisible.NONE);

  return (
    <>
      <FooterBar>
        <FooterBarItem
          active={true}
          disabled={props.disabled}
          text={props.createButtonText}
          onPress={() => setModalVisible(ModalVisible.CONFIRMATION)}
        />
      </FooterBar>

      <ModalConfirmation
        isVisible={modalVisible === ModalVisible.CONFIRMATION}
        onModalHide={() => setModalVisible(ModalVisible.NONE)}
        content={props.confirmationMessage}
        onConfirm={() => {
          props.onCreate();
          setModalVisible(ModalVisible.NONE);
        }}
      />
    </>
  );
};

ReportActionBar.defaultProps = {
  disabled: false,
};
