import request from '../../../request';
import { useMutation } from '@tanstack/react-query';
import {
  CreateYearReportParams,
  CreateYearReportResponse,
  PrintYearReportParams,
} from '../../../types/cash-register/year-report.types';
import { PrintReceiptResponse } from '../../../types/vendor/carts-types';

export const useYearReportMutations = () => {
  const createYearReport = useMutation(
    async (params: CreateYearReportParams) =>
      request({
        method: 'post',
        url: '/store/year_reports.json',
        params: { year_report: params },
      }) as Promise<CreateYearReportResponse>,
  );

  const printYearReport = useMutation(
    async ({ id, ...params }: PrintYearReportParams) =>
      request({
        method: 'post',
        url: `/store/year_reports/${id}/print.json`,
        params: params,
      }) as Promise<PrintReceiptResponse>,
  );

  return { createYearReport, printYearReport };
};
