import request from '../../../request';
import { useQuery } from '@tanstack/react-query';
import { CashierReportResponse } from '../../../types/cash-register/cashier-report.types';

export const useCashierReportQueries = (id: number) => {
  const fetchCashierReport = useQuery(
    ['cashierReports', id],
    async () =>
      request({
        method: 'get',
        url: `/store/cashier_reports/${id}.json`,
      }) as Promise<CashierReportResponse>,
  );

  return { fetchCashierReport };
};
