import React from 'react';
import { View } from 'react-native';
import {
  DatePickerInput,
  DatePickerInputView,
} from '../forms/date-picker-input';
import { useT } from '../../utils/helper';

interface DateFilterProps {
  view: DatePickerInputView;
  onDateChange: (date: Date | null) => void;
}

export const DateFilter = (props: DateFilterProps) => {
  const t = useT('forms', { keyPrefix: 'datePicker' });

  return (
    <View>
      <DatePickerInput
        onChange={date => props.onDateChange(date ? date : null)}
        label={t(`label.${props.view}`).toString()}
        placeholder={t(`placeholder.${props.view}`).toString()}
        view={props.view}
      />
    </View>
  );
};

DateFilter.defaultProps = {
  view: 'days',
};
