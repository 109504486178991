import React, { forwardRef, useEffect, useRef } from 'react';
import AirDatepicker, { AirDatepickerOptions } from 'air-datepicker';
import localeEn from 'air-datepicker/locale/de';
import tw from 'twrnc';
import { useT } from '../../utils/helper';

export type AirDatepickerInstance = {
  $datepicker: HTMLElement;
  show: () => void;
  hide: () => void;
};

export const WebDatePicker = forwardRef(
  (props: AirDatepickerOptions, ref: any) => {
    const t = useT();
    const $input = useRef<HTMLInputElement>(null);
    const dp = ref;
    // submit button only needed for timepicker
    // on datepicker the modal will be closed automatically
    const submitButton = {
      content: t('submit'),
      onClick: () => {
        ref.current.hide();
      },
    };

    // Start init
    useEffect(() => {
      // Save instance for the further update
      if ($input.current) {
        dp.current = new AirDatepicker($input.current, {
          locale: localeEn,
          buttons: props.timepicker ? ['clear', submitButton] : ['clear'],
          onChangeView: (_view: string) => {
            dp.current.$datepicker.style.zIndex = 10000;
          },
          onShow: (isFinished: boolean) => {
            if (!isFinished) {
              // react-native-modal has 9999 z-index
              dp.current.$datepicker.style.zIndex = 10000;
            }
          },
          ...props,
        });
      }
    }, []);

    useEffect(() => {
      // Update if props are changed
      // @ts-ignore
      dp.current.update({ ...props });
    }, [props]);

    if ($input.current) {
    }
    return <input ref={$input} style={tw`hidden`} />;
  },
);
