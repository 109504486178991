import React from 'react';
import { ScreenContainer } from './screen-container';
import { tw } from '../../../utils/tailwind';
import { View } from 'react-native';
import { ReportNavigation } from '../../navigations/report-navigation';
import { useWindowSize } from '../../../hooks/use-window-size';
import { REPORT_ACTION_BAR_HEIGHT } from '../reports/report-action-bar';
import { useNavigation } from '@react-navigation/native';
import { AppStackScreenProps } from '../../../routes';
import { useTenant } from '../../../contexs/tenant-context';

interface ReportIndexScreenProps {
  title: string;
  filterView: React.ReactNode;
  listView: React.ReactNode;
  worfklowPendingView: React.ReactNode;
  workflowPending: boolean;
}

export const ReportIndexScreen = (props: ReportIndexScreenProps) => {
  const { height, headerHeight } = useWindowSize();
  const navigation =
    useNavigation<AppStackScreenProps<'CashPointClosings'>['navigation']>();
  const { tenant } = useTenant();

  const contentHeight = height - headerHeight - REPORT_ACTION_BAR_HEIGHT;
  // vendor only has access to cash point closing not other reports
  const showNavigation = !tenant.vendor_cash_point_closing_enabled;
  const reportNavigationHeight = showNavigation ? 60 : 0;
  const flatListHeight = contentHeight - (260 + reportNavigationHeight);

  return (
    <ScreenContainer
      title={props.title}
      scrollable={false}
      containerStyle={tw`gap-y-[18px] max-h-[${contentHeight}px] min-h-[${contentHeight}px]`}
      headerWrapperStyle={tw`w-full mx-auto max-w-[800px]`}
      onBackPress={() => navigation.navigate('Root')}>
      <View style={tw`w-full mx-auto max-w-[800px] gap-y-[18px]`}>
        {showNavigation ? <ReportNavigation /> : null}

        {!props.workflowPending ? (
          <>
            {props.filterView}
            <View style={tw`max-h-[${flatListHeight}px]`}>
              {props.listView}
            </View>
          </>
        ) : null}

        {props.worfklowPendingView}
      </View>
    </ScreenContainer>
  );
};
