import * as Sentry from '@sentry/react-native';
import env from './src/env';
import { Platform } from 'react-native';

if (env.SENTRY_DSN) {
  const integrations =
    Platform.OS === 'web' ? [Sentry.browserTracingIntegration()] : [];

  Sentry.init({
    dsn: env.SENTRY_DSN,
    tracesSampleRate: 0.5,
    integrations: integrations,
  });
}
