import React from 'react';
import { RouteProp } from '@react-navigation/native';
import { AppStackParamList, AppStackScreenProps } from '../../../routes';
import { ScreenContainer } from '../../../components/cash-register/shared/screen-container';
import { View } from 'react-native';
import { tw } from '../../../utils/tailwind';
import { useCashPointClosingQueries } from '../../../api/hooks/cash-register/cash-point-closings/cash-point-closing-queries';
import QuerySuspense from '../../../api/query-suspense';
import { PointClosingStatSummary } from '../../../components/cash-register/shared/point-closing-stat-summary';

interface CashPointClosingScreenProps {
  route: RouteProp<AppStackParamList, 'CashPointClosing'>;
  navigation: AppStackScreenProps<'CashPointClosing'>['navigation'];
}

export const CashPointClosingScreen = (props: CashPointClosingScreenProps) => {
  const { id } = props.route.params;

  const { fetchCashPointClosing } = useCashPointClosingQueries(id);

  return (
    <QuerySuspense
      query={fetchCashPointClosing}
      onSuccess={cashPointClosing => (
        <ScreenContainer
          title={cashPointClosing.title}
          containerStyle={tw`bg-white`}
          headerWrapperStyle={tw`w-full m-auto max-w-[800px] justify-between`}
          scrollable={true}
          onBackPress={() => props.navigation.navigate('CashPointClosings')}>
          <View style={tw`w-full m-auto max-w-[800px]`}>
            <PointClosingStatSummary pointClosing={cashPointClosing} />
          </View>
        </ScreenContainer>
      )}
    />
  );
};
