import React from 'react';
import { tw } from '../../../utils/tailwind';
import { useYearReportsQueries } from '../../../api/hooks/cash-register/year-reports/use-year-reports-queries';
import { format } from 'date-fns';
import { STANDARD_DATE_FORMAT } from '../../../utils/helper';
import { YearReportListItem } from './year-report-list-item';
import { FlatListPagination } from '../../../components/flat-list-pagination';

interface YearReportListProps {
  date: Date | null;
}

export const YearReportList = (props: YearReportListProps) => {
  // Queries
  const { fetchYearReports } = useYearReportsQueries({
    q: {
      date_eq: props.date ? format(props.date, STANDARD_DATE_FORMAT) : null,
    },
  });

  return (
    <FlatListPagination
      query={fetchYearReports}
      refreshing={fetchYearReports.isRefetching}
      onRefresh={fetchYearReports.refetch}
      collectionName="year_reports"
      contentContainerStyle={tw`gap-y-1.5`}
      keyExtractor={item => item.id.toString()}
      renderItem={({ item }) => (
        <YearReportListItem key={item.id} yearReport={item} />
      )}
    />
  );
};
