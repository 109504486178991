import React, { useRef, useState } from 'react';
import { STANDARD_DATE_TIME_FORMAT, useT } from '../../../utils/helper';
import { CashPointClosingList } from './cash-point-closing-list';
import { useCashPointClosingMutations } from '../../../api/hooks/cash-register/cash-point-closings/use-cash-point-closing-mutations';
import { useAlert } from '../../../contexs/alert-context';
import { DateFilter } from '../../../components/filters/date-filter';
import { useAuth } from '../../../contexs/auth-context';
import { WorkflowListener } from '../../../components/workflow-listener';
import { ReportActionBar } from '../../../components/cash-register/reports/report-action-bar';
import { format } from 'date-fns';
import { useCashPointClosingNewQuery } from '../../../api/hooks/cash-register/cash-point-closings/use-cash-point-closing-new-query';
import { ReportIndexScreen } from '../../../components/cash-register/shared/report-index-screen';
import { useTenant } from '../../../contexs/tenant-context';
import { processPOSCommand } from '../../../utils/print-module';

interface PendingWorkflow {
  id: number;
  message: string;
}

export const CashPointClosingsScreen = () => {
  // Standard Hooks
  const t = useT('screens', { keyPrefix: 'CashPointClosings' });
  // Custom Hooks
  const { tenant } = useTenant();
  const alert = useAlert();
  const { currentCashier } = useAuth();
  const pwId = useRef<number | null>(null);
  // States
  const [date, setDate] = useState<Date | null>(null);
  const [pendingWorkflow, setPendingWorkflow] =
    useState<PendingWorkflow | null>(null);
  // Queries
  const { fetchNewCashPointClosing } = useCashPointClosingNewQuery();
  const { createCashPointClosing } = useCashPointClosingMutations();
  const { printCashPointClosing } = useCashPointClosingMutations();

  const startedAt = fetchNewCashPointClosing.data?.started_at || new Date();
  // Handlers
  const handleAddNewCashPointClosing = async () => {
    // If is not created by vendor, cashier is required
    if (!tenant.vendor_cash_point_closing_enabled && !currentCashier) {
      return;
    }

    createCashPointClosing
      .mutateAsync({ cashier_id: currentCashier?.id })
      .then(data => {
        pwId.current = data.workflow.id;
        setPendingWorkflow({
          id: data.workflow.id,
          message: data.message,
        });
      })
      .catch(response => {
        alert.show(response.data);
      });
  };

  const handleWebSocketMessage = (message: any) => {
    if (message.id !== pwId.current) {
      return;
    }

    if (message.status === 'failed' && message.workflow_error_messages) {
      alert.show(message.workflow_error_messages.join('\n'));
    }

    if (message.cash_point_closing_id) {
      print(message.cash_point_closing_id);
    }

    setPendingWorkflow(null);
  };

  const print = (id: number) => {
    printCashPointClosing
      .mutateAsync({
        id: id,
        validate_printer_configuration: true,
      })
      .then(data => {
        if (data.print_jobs.length) {
          processPOSCommand(data.print_jobs);
        }
      });
  };

  return (
    <>
      <ReportIndexScreen
        title={t('pageTitle')}
        filterView={
          <DateFilter onDateChange={selectedDate => setDate(selectedDate)} />
        }
        listView={<CashPointClosingList date={date} />}
        worfklowPendingView={
          <WorkflowListener
            loadingMessage={pendingWorkflow?.message}
            channel="CreateCashPointClosingWorkflow"
            onMessage={handleWebSocketMessage}
          />
        }
        workflowPending={!!pendingWorkflow}
      />

      <ReportActionBar
        disabled={!!pendingWorkflow}
        confirmationMessage={t('createConfirmationMessage', {
          fromDate: format(startedAt, STANDARD_DATE_TIME_FORMAT),
        })}
        createButtonText={t('addNewCashPointClosing')}
        onCreate={handleAddNewCashPointClosing}
      />
    </>
  );
};
