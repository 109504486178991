import request from '../../../request';
import { useInfiniteQuery } from '@tanstack/react-query';
import {
  CashierReportsParams,
  CashierReportsResponse,
} from '../../../types/cash-register/cashier-report.types';

export const useCashierReportsQueries = (params: CashierReportsParams) => {
  const fetchCashierReports = useInfiniteQuery(
    ['cashierReports', params],
    async ({ pageParam }) =>
      request({
        method: 'get',
        url: '/store/cashier_reports.json',
        params: { ...params, page: pageParam },
      }) as Promise<CashierReportsResponse>,
    {
      getNextPageParam: (lastPage, _pages) => {
        // Return the next page number if there are more pages
        return lastPage.next_page || false;
      },
    },
  );

  return { fetchCashierReports };
};
