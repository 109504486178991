import React from 'react';
import { View } from 'react-native';
import { tw } from '../../../utils/tailwind';
import { SelectList } from '../../forms/select-list';
import { useT } from '../../../utils/helper';
import { ModalConfirmation } from '../../shared/modals/modal-confirmation';

interface ModalSetDiscountProps {
  isVisible: boolean;
  onModalHide: () => void;
}

export const ModalSetDiscount = (props: ModalSetDiscountProps) => {
  // Standard Hooks
  const t = useT();

  // Dummy data
  const options = [
    { key: 'percentage', value: 'Prozent-Betrag' },
    { key: 'fixedValue', value: 'Fix-Betrag' },
  ];

  return (
    <ModalConfirmation
      isVisible={props.isVisible}
      content={t('discount').toString()}
      onConfirm={() => {}}
      onModalHide={props.onModalHide}>
      <View style={tw`flex-1 mb-5 w-full z-10`}>
        <SelectList
          setSelected={_selectedOption => {}}
          data={options}
          placeholder={t('discountTypePlaceholder').toString()}
          dropdownStyles={tw`top-[60px]`}
        />
      </View>
    </ModalConfirmation>
  );
};
