import request from '../../../request';
import { useQuery } from '@tanstack/react-query';
import { MonthReportResponse } from '../../../types/cash-register/month-report.types';

export const useMonthReportQueries = (id: number) => {
  const fetchMonthReport = useQuery(
    ['monthReports', id],
    async () =>
      request({
        method: 'get',
        url: `/store/month_reports/${id}.json`,
      }) as Promise<MonthReportResponse>,
  );

  return { fetchMonthReport };
};
