import React from 'react';
import { RouteProp } from '@react-navigation/native';
import { AppStackParamList, AppStackScreenProps } from '../../../routes';
import { useT } from '../../../utils/helper';
import { useYearReportQueries } from '../../../api/hooks/cash-register/year-reports/use-year-report-queries';
import QuerySuspense from '../../../api/query-suspense';
import { ScreenContainer } from '../../../components/cash-register/shared/screen-container';
import { tw } from '../../../utils/tailwind';
import { View, Text } from 'react-native';
import { ReportStatGroup } from '../../../components/report/report-stat-group';
import { format } from 'date-fns';
import { ReportStatItem } from '../../../components/report/report-stat-item';
import { ReportCounts } from '../../../components/report/report-counts';
import { ReportStatCategoryDetail } from '../../../components/report/report-stat-category-detail';
import { ReportRevenueAfterCancellation } from '../../../components/report/report-revenue-after-cancellation';
import { ReportDayStats } from '../../../components/report/report-day-stats';

interface YearReportShowScreenProps {
  route: RouteProp<AppStackParamList, 'YearReport'>;
  navigation: AppStackScreenProps<'YearReport'>['navigation'];
}

export const YearReportShowScreen = (props: YearReportShowScreenProps) => {
  const id = props.route.params.id;
  const t = useT('screens', { keyPrefix: 'CashPointClosing' });

  const { fetchYearReport } = useYearReportQueries(id);

  return (
    <QuerySuspense
      query={fetchYearReport}
      onSuccess={yearReport => (
        <ScreenContainer
          title={yearReport.title}
          containerStyle={tw`bg-white`}
          headerWrapperStyle={tw`w-full m-auto max-w-[800px] justify-between m-auto`}
          scrollable={true}
          onBackPress={() => props.navigation.navigate('YearReports')}>
          <View style={tw`w-full m-auto max-w-[800px]`}>
            <ReportStatGroup>
              {/* Creation Detail */}
              <View style={tw`flex-row`}>
                <Text style={tw`text-base`}>
                  {format(yearReport.created_at, 'dd.MM.yyyy - HH:mm')}
                </Text>
                <Text style={tw`text-base`}> - </Text>
                <Text style={tw`text-base text-success`}>
                  {yearReport.cashier.name}
                </Text>
              </View>

              {/* Year name */}
              <View style={tw`flex-row`}>
                <Text style={tw`text-base`}>{yearReport.year_name}</Text>
              </View>
            </ReportStatGroup>

            <ReportStatGroup>
              <ReportStatItem
                title={t('yearRevenue')}
                value={yearReport.year_revenue}
              />
            </ReportStatGroup>

            <ReportCounts stats={yearReport.stats.year_stats} />
            <ReportStatCategoryDetail
              statsCategory={yearReport.stats.year_stats.invoice}
              category="invoice"
            />
            <ReportStatCategoryDetail
              statsCategory={yearReport.stats.year_stats.cancellation}
              category="cancellation"
            />
            <ReportRevenueAfterCancellation
              stat={yearReport.stats.year_stats.summed.sum}
            />
            <ReportDayStats stats={yearReport.stats.month_stats} />
          </View>
        </ScreenContainer>
      )}
    />
  );
};
