import request from '../../../request';
import { useInfiniteQuery } from '@tanstack/react-query';
import {
  CashPointClosingsParams,
  CashPointClosingsResponse,
} from '../../../types/cash-register/cash-point-closing.types';

export const useCashPointClosingsQueries = (
  params: CashPointClosingsParams,
) => {
  const fetchCashPointClosings = useInfiniteQuery(
    ['cashPointClosings', params],
    async ({ pageParam }) =>
      request({
        method: 'get',
        url: '/store/cash_point_closings.json',
        params: { ...params, page: pageParam },
      }) as Promise<CashPointClosingsResponse>,
    {
      getNextPageParam: (lastPage, _pages) => {
        // Return the next page number if there are more pages
        return lastPage.next_page || false;
      },
    },
  );

  return { fetchCashPointClosings };
};
