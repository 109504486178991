import React from 'react';
import { tw } from '../../utils/tailwind';
import {
  View,
  Text,
  TextInput,
  Platform,
  TouchableOpacity,
} from 'react-native';
import { format } from 'date-fns';
import { CalendarSVG } from '../../../assets/svg';
import { AirDatepickerInstance, WebDatePicker } from '../web/web-date-picker';
import DatePicker from 'react-native-date-picker';

const FORMAT_DATE_BASED_ON_VIEW = {
  days: 'dd/MM/yyyy',
  months: 'MMMM yyyy',
  years: 'yyyy',
};

export type DatePickerInputView = 'days' | 'months' | 'years';

interface DatePickerInputProps {
  timepicker: boolean;
  onChange: (date: Date) => void;
  label?: string;
  format?: string;
  placeholder?: string;
  initialValue?: Date | null;
  view: DatePickerInputView;
}

export const DatePickerInput = (props: DatePickerInputProps) => {
  const datePickerRef = React.useRef<AirDatepickerInstance>();
  const [open, setOpen] = React.useState(false);
  const [date, setDate] = React.useState(props.initialValue);

  const displayDate = date
    ? format(date, props.format || FORMAT_DATE_BASED_ON_VIEW[props.view])
    : '';

  const openCalender = () => {
    if (Platform.OS === 'web') {
      datePickerRef.current?.show();
    } else {
      setOpen(true);
    }

    return true;
  };

  const setDateAndSubmit = (selectedDate: Date) => {
    setOpen(false);
    setDate(selectedDate);

    if (selectedDate !== date) {
      props.onChange(selectedDate);
    }

    // clearing date won't auto close the date picker even if autoClose is true
    if (Platform.OS === 'web' && !props.timepicker) {
      datePickerRef.current?.hide();
    }
  };

  return (
    <View>
      {props.label ? (
        <Text
          style={tw`md:text-lg md:leading-none text-base leading-none text-gray-600 mb-1`}>
          {props.label}
        </Text>
      ) : null}
      <TextInput
        placeholder={props.placeholder}
        style={tw`h-14 py-4 px-3 w-full rounded-lg text-lg font-medium leading-none placeholder-gray-400 border border-gray-400 text-gray-800`}
        value={displayDate}
        inputMode="numeric"
        onFocus={() => openCalender()}
      />
      <TouchableOpacity
        style={tw`absolute top-[40px] right-[15px]`}
        onPress={() => openCalender()}>
        <CalendarSVG style={tw`w-[20px] h-[20px]`} />
      </TouchableOpacity>
      <>
        {Platform.OS === 'web' ? (
          <WebDatePicker
            timepicker={props.timepicker}
            isMobile={true}
            ref={datePickerRef}
            autoClose={true}
            view={props.view}
            minView={props.view}
            onSelect={value => setDateAndSubmit(value.date as Date)} // value.date can be Date | Date[] but we use single date picker here
          />
        ) : (
          <DatePicker
            modal
            mode="date"
            open={open}
            date={date || new Date()} // both android and ios need date object
            onConfirm={selectedDate => setDateAndSubmit(selectedDate)}
            onCancel={() => setOpen(false)}
          />
        )}
      </>
    </View>
  );
};

DatePickerInput.defaultProps = {
  view: 'days',
  timepicker: false,
};
