import React from 'react';
import { ReportStatGroup } from './report-stat-group';
import { ReportStatItem } from './report-stat-item';
import { useT } from '../../utils/helper';
import { CashPointClosing } from '../../api/types/cash-register/cash-point-closing.types';

const IGNORED_KEYS = ['summed'];

interface ReportCountsProps {
  stats: CashPointClosing['stats'];
}

export const ReportCounts = (props: ReportCountsProps) => {
  const t = useT('screens', { keyPrefix: 'CashPointClosing' });

  return (
    <ReportStatGroup>
      {Object.keys(props.stats).map(
        key =>
          !IGNORED_KEYS.includes(key) && (
            <ReportStatItem
              key={key}
              title={t(`${key}Count`)}
              value={props.stats[key].count}
              formatValue={false}
            />
          ),
      )}
    </ReportStatGroup>
  );
};
