import React from 'react';
import { tw } from '../../../utils/tailwind';
import { useMonthReportsQueries } from '../../../api/hooks/cash-register/month-reports/use-month-reports-queries';
import { format } from 'date-fns';
import { STANDARD_DATE_FORMAT } from '../../../utils/helper';
import { MonthReportListItem } from './month-report-list-item';
import { FlatListPagination } from '../../../components/flat-list-pagination';

interface MonthReportListProps {
  date: Date | null;
}

export const MonthReportList = (props: MonthReportListProps) => {
  // Queries
  const { fetchMonthReports } = useMonthReportsQueries({
    q: {
      date_eq: props.date ? format(props.date, STANDARD_DATE_FORMAT) : null,
    },
  });

  return (
    <FlatListPagination
      query={fetchMonthReports}
      refreshing={fetchMonthReports.isRefetching}
      onRefresh={fetchMonthReports.refetch}
      collectionName="month_reports"
      contentContainerStyle={tw`gap-y-1.5`}
      keyExtractor={item => item.id.toString()}
      renderItem={({ item }) => (
        <MonthReportListItem key={item.id} monthReport={item} />
      )}
    />
  );
};
