import React, { useMemo } from 'react';
import { ScreenContainer } from '../../../components/cash-register/shared/screen-container';
import { tw } from '../../../utils/tailwind';
import { FooterBar } from '../../../components/cash-register/footer-bar/footer-bar';
import { FooterBarItem } from '../../../components/cash-register/footer-bar/footer-bar-item';
import { ScrollView } from 'react-native';
import { useT } from '../../../utils/helper';
import { AppStackScreenProps } from '../../../routes';
import { useInvoiceQrQuery } from '../../../api/hooks/cash-register/invoices/use-invoice-qr-query';
import QuerySuspense from '../../../api/query-suspense';
import QRCode from 'react-qr-code';

interface InvoiceShowQrScreenProps {
  route: AppStackScreenProps<'InvoiceShowQr'>['route'];
  navigation: AppStackScreenProps<'InvoiceShowQr'>['navigation'];
}

export const InvoiceShowQrScreen = (props: InvoiceShowQrScreenProps) => {
  // params
  const id = props.route.params.id;
  // Standard Hooks
  const t = useT();
  // Queries
  const { fetchInvoiceQr } = useInvoiceQrQuery(id);
  console.log(props.navigation.getState());

  // If the previous screen is InvoiceShow, display the finish button
  const shouldDsiplayFinishButton = useMemo(() => {
    const navigationState = props.navigation.getState();

    return (
      navigationState.routes[navigationState.index - 1]?.name === 'InvoiceShow'
    );
  }, []);

  return (
    <>
      <QuerySuspense
        query={fetchInvoiceQr}
        onSuccess={data => (
          <>
            <ScreenContainer
              title={data.store_table?.name}
              scrollable={true}
              headerWrapperStyle={tw`max-w-[540px] self-center w-full mb-6`}
              containerStyle={tw`my-auto h-full`}>
              <ScrollView
                style={tw`max-w-[540px] self-center w-full gap-y-6`}
                contentContainerStyle={tw`justify-center h-full`}>
                <QRCode value={data.invoice_url} style={tw`self-center`} />
              </ScrollView>
            </ScreenContainer>
            {/* Action bar */}
            {shouldDsiplayFinishButton ? (
              <FooterBar>
                <FooterBarItem
                  text={t('finish')}
                  active={true}
                  onPress={() =>
                    props.navigation.navigate('StoreArea', {
                      store_area_id: data.store_table.store_area_id,
                    })
                  }
                  textStyles={tw`text-2xl leading-none text-gray-800 text-white`}
                />
              </FooterBar>
            ) : null}
          </>
        )}
      />
    </>
  );
};
