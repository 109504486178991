import { useEffect } from 'react';
import env from '../../env';
import useActionCable from './use-action-cable';
import useChannel from './use-channel';
import { WorkflowChannelPayload } from '../types';
import { useAuth } from '../../contexs/auth-context';

export default function useWorkflowChannel(
  backgroundJobName: string,
  onReceive: (workflow: WorkflowChannelPayload) => void,
) {
  const { authData } = useAuth();
  const { actionCable } = useActionCable(
    `${env.WEBSOCKET_URL}?token=${authData?.token}`,
  );
  const { subscribe, unsubscribe } = useChannel(actionCable);

  useEffect(() => {
    subscribe(
      {
        channel: 'BackgroundJobChannel',
        background_job_name: backgroundJobName,
      },
      {
        received: onReceive,
      },
    );
    return () => {
      unsubscribe();
    };
  }, []);
}
