import { useEffect } from 'react';
import { useAuth } from '../../contexs/auth-context';
import env from '../../env';
import { CashDrawerChannelPayload } from '../types';
import useActionCable from './use-action-cable';
import useChannel from './use-channel';
import { openCashDrawer } from '../../utils/cash-drawer-module';

export default async function useCashDrawerChannel() {
  const { authData } = useAuth();
  const { actionCable } = useActionCable(
    `${env.WEBSOCKET_URL}?token=${authData?.token}`,
  );
  const { subscribe, unsubscribe } = useChannel(actionCable);

  useEffect(() => {
    subscribe(
      { channel: 'CashDrawerChannel' },
      {
        received: (payload: CashDrawerChannelPayload) => {
          if (payload.action === 'open') {
            window.ReactNativeWebView?.postMessage(
              JSON.stringify({
                type: 'print',
                payload: {
                  printer: { number_of_bons: 1 },
                  pos_command: '1014000000',
                },
              }),
            );
            openCashDrawer();
          }
        },
        connected: () => {},
      },
    );
    return () => {
      unsubscribe();
    };
  }, []);
}
