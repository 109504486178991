import React, { useState } from 'react';
import { ModalConfirmation } from '../../shared/modals/modal-confirmation';
import { View } from 'react-native';
import { tw } from '../../../utils/tailwind';
import { STANDARD_DATE_TIME_FORMAT, useT } from '../../../utils/helper';
import { DatePickerInput } from '../../forms/date-picker-input';

interface ModalDateRangeInputProps {
  isVisible: boolean;
  initialStartDate: Date | null;
  initialEndDate: Date | null;
  onModalHide: () => void;
  onDateRangeSelected: (startDate: Date, endDate: Date) => void;
}

export const ModalDateRangeInput = (props: ModalDateRangeInputProps) => {
  const t = useT();
  const format = STANDARD_DATE_TIME_FORMAT.replace(/\./g, '/');

  // States
  const [startDate, setStartDate] = useState<Date | null>(
    props.initialStartDate || null,
  );
  const [endDate, setEndDate] = useState<Date | null>(
    props.initialEndDate || null,
  );

  // Handle confirmation (when the user clicks confirm)
  const handleConfirm = () => {
    if (startDate && endDate) {
      props.onDateRangeSelected(startDate, endDate); // Pass the selected date range back to the parent
    }
  };

  return (
    <ModalConfirmation
      isVisible={props.isVisible}
      content={t('chooseDate').toString()}
      onConfirm={handleConfirm}
      onModalHide={props.onModalHide}>
      <View style={tw`flex-1 mb-5 gap-y-3 w-full z-10`}>
        <DatePickerInput
          timepicker={true}
          initialValue={startDate}
          format={format}
          onChange={date => setStartDate(date)} // Update start date
          label={t('dateStart').toString()}
          placeholder={t('chooseDate').toString()}
        />

        <DatePickerInput
          timepicker={true}
          initialValue={endDate}
          format={format}
          onChange={date => setEndDate(date)} // Update end date
          label={t('dateEnd').toString()}
          placeholder={t('chooseDate').toString()}
        />
      </View>
    </ModalConfirmation>
  );
};

ModalDateRangeInput.defaultProps = {
  initialStartDate: null,
  initialEndDate: null,
};
