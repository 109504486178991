import React, { useState } from 'react';
import { ScreenContainer } from '../../../../components/cash-register/shared/screen-container';
import { tw } from '../../../../utils/tailwind';
import { FooterBar } from '../../../../components/cash-register/footer-bar/footer-bar';
import { FooterBarItem } from '../../../../components/cash-register/footer-bar/footer-bar-item';
import { OrderSessionItems } from '.././order-session-items';
import { ModalSetDiscount } from '../../../../components/cash-register/modals/modal-set-discount';
import { useWindowDimensions, View } from 'react-native';
import { OrderSession } from '../../../../api/types/cash-register/order-session.types';
import { useT } from '../../../../utils/helper';
import {
  SelectedLineItem,
  usePayment,
} from '../../../../contexs/payment-context';
import { useInvoiceMutations } from '../../../../api/hooks/cash-register/invoices/use-invoice-mutation';
import { useQueryClient } from '@tanstack/react-query';
import { useNavigation } from '@react-navigation/native';
import { AppStackScreenProps } from '../../../../routes';
import { useAuth } from '../../../../contexs/auth-context';

enum ModalVisible {
  SetDiscount = 'setDiscount',
  None = 'none',
}

interface PaymentItemSelectionProps {
  orderSession: OrderSession;
  defaultSelected: boolean;
}

export const PaymentItemSelection = (props: PaymentItemSelectionProps) => {
  // Standard Hooks
  const t = useT();
  const { height } = useWindowDimensions();
  const navigation =
    useNavigation<AppStackScreenProps<'OrderSessionPay'>['navigation']>();
  // Providers
  const queryClient = useQueryClient();
  const payment = usePayment();
  const { currentCashier } = useAuth();
  // Queries
  const { createInvoice } = useInvoiceMutations();
  // States
  const [modalVisible, setModalVisible] = useState<ModalVisible>();
  const [selectedLineItems, setSelectedLineItems] = useState<
    SelectedLineItem[]
  >([]);

  // Callbacks
  const onPressPay = () => {
    payment.setStep('INVOICE_CREATION');

    executePayment();
  };

  const executePayment = () => {
    if (!currentCashier) {
      return false;
    }

    createInvoice
      .mutateAsync({
        order_session_id: props.orderSession.id,
        payment_method: 'cash',
        cart_type: 'table_order',
        cashier_id: currentCashier.id,
        line_items_attributes: selectedLineItems.map(item => ({
          id: item.id,
          quantity: item.selectedQuantity,
        })),
      })
      .then(response => {
        setSelectedLineItems([]);
        queryClient.invalidateQueries(['orderSessions', props.orderSession.id]);
        queryClient.invalidateQueries(['invoices']);
        queryClient.invalidateQueries(['storeAreas']);

        navigation.navigate('InvoiceWorkflowPending', {
          id: response.workflow.id,
        });

        // Reset payment step
        payment.setStep('ITEM_SELECTION');
      });
  };

  if (payment.step !== 'ITEM_SELECTION') {
    return null;
  }

  return (
    <>
      <ScreenContainer
        title={
          props.orderSession.store_table.virtual
            ? t('virtualTable')
            : props.orderSession.store_table.name
        }
        scrollable={true}
        onBackPress={() =>
          navigation.navigate('StoreArea', {
            store_area_id: props.orderSession.store_table.store_area_id,
          })
        }
        headerWrapperStyle={tw`max-w-[540px] self-center w-full`}>
        <View
          style={tw`max-w-[540px] self-center w-full overflow-scroll h-full md:max-h-[${
            height - 200
          }px] max-h-[${height - 250}px]`}>
          <OrderSessionItems
            lineItems={props.orderSession.line_items}
            displayTotal={true}
            defaultSelected={props.defaultSelected}
            onChange={setSelectedLineItems}
          />
        </View>
      </ScreenContainer>

      {/* Modals */}
      <ModalSetDiscount
        isVisible={modalVisible === ModalVisible.SetDiscount}
        onModalHide={() => {
          setModalVisible(ModalVisible.None);
        }}
      />

      {/** Action bar */}
      <FooterBar style={tw`gap-0 flex-wrap`}>
        {/** Discount Button */}
        <FooterBarItem
          text={t('discount')}
          active={false}
          disabled={selectedLineItems.length === 0}
          onPress={() => setModalVisible(ModalVisible.SetDiscount)}
          buttonStyles={[tw`md:basis-1/3 basis-1/2`]}
          textStyles={tw`text-2xl leading-none`}
        />
        {/** Voucher Button */}
        <FooterBarItem
          text={t('voucher')}
          active={false}
          disabled={selectedLineItems.length === 0}
          onPress={() => {}}
          buttonStyles={[
            tw`md:basis-1/3 basis-1/2 border-0 border-l border-solid border-white`,
          ]}
          textStyles={tw`text-2xl leading-none`}
        />
        {/** Pay Button */}
        <FooterBarItem
          text={t('pay')}
          active={true}
          disabled={selectedLineItems.length === 0}
          onPress={onPressPay}
          buttonStyles={[tw`md:basis-1/3 basis-1/2`]}
          textStyles={tw`text-2xl leading-none`}
        />
      </FooterBar>
    </>
  );
};

PaymentItemSelection.defaultProps = {
  defaultSelected: false,
};
