import React, { useState } from 'react';
import { useT } from '../../../utils/helper';
import { useAlert } from '../../../contexs/alert-context';
import { useAuth } from '../../../contexs/auth-context';
import { DateFilter } from '../../../components/filters/date-filter';
import { useMonthReportMutations } from '../../../api/hooks/cash-register/month-reports/use-month-report-mutations';
import { MonthReportList } from './month-report-list';
import { ReportActionBar } from '../../../components/cash-register/reports/report-action-bar';
import { format } from 'date-fns';
import { WorkflowListener } from '../../../components/workflow-listener';
import { ReportIndexScreen } from '../../../components/cash-register/shared/report-index-screen';

interface PendingWorkflow {
  message: string;
}

export const MonthReportListScreen = () => {
  // Standard Hooks
  const t = useT('screens', { keyPrefix: 'MonthReports' });
  // Custom Hooks
  const alert = useAlert();
  const { currentCashier } = useAuth();
  // States
  const [date, setDate] = useState<Date | null>(null);
  const [pendingWorkflow, setPendingWorkflow] =
    useState<PendingWorkflow | null>(null);
  // Queries
  const { createMonthReport } = useMonthReportMutations();

  // Handlers
  const handleAddNewMonthReport = async () => {
    const month = new Date().getMonth() + 1;
    const year = new Date().getFullYear();

    if (!currentCashier) {
      return;
    }

    createMonthReport
      .mutateAsync({ cashier_id: currentCashier.id, month, year })
      .then(data => {
        setPendingWorkflow(data);
      })
      .catch(response => {
        alert.show(response.data);
      });
  };

  const handleWebSocketMessage = (message: any) => {
    if (message.cashier_id !== currentCashier?.id) {
      return;
    }

    setPendingWorkflow(null);
  };

  return (
    <>
      <ReportIndexScreen
        title={t('pageTitle')}
        filterView={
          <DateFilter
            view="months"
            onDateChange={selectedDate => setDate(selectedDate)}
          />
        }
        listView={<MonthReportList date={date} />}
        worfklowPendingView={
          <WorkflowListener
            loadingMessage={pendingWorkflow?.message}
            channel="create_month_report"
            onMessage={handleWebSocketMessage}
          />
        }
        workflowPending={!!pendingWorkflow}
      />

      <ReportActionBar
        disabled={createMonthReport.isLoading}
        confirmationMessage={t('createConfirmationMessage', {
          month: format(new Date(), 'MMMM'),
        })}
        createButtonText={t('addNewMonthReport')}
        onCreate={handleAddNewMonthReport}
      />
    </>
  );
};
