import React from 'react';
import { RouteProp } from '@react-navigation/native';
import { AppStackParamList, AppStackScreenProps } from '../../../routes';
import { useT } from '../../../utils/helper';
import { useMonthReportQueries } from '../../../api/hooks/cash-register/month-reports/use-month-report-queries';
import QuerySuspense from '../../../api/query-suspense';
import { ScreenContainer } from '../../../components/cash-register/shared/screen-container';
import { tw } from '../../../utils/tailwind';
import { View, Text } from 'react-native';
import { ReportStatGroup } from '../../../components/report/report-stat-group';
import { format } from 'date-fns';
import { ReportStatItem } from '../../../components/report/report-stat-item';
import { ReportCounts } from '../../../components/report/report-counts';
import { ReportStatCategoryDetail } from '../../../components/report/report-stat-category-detail';
import { ReportRevenueAfterCancellation } from '../../../components/report/report-revenue-after-cancellation';
import { ReportDayStats } from '../../../components/report/report-day-stats';

interface MonthReportShowScreenProps {
  route: RouteProp<AppStackParamList, 'MonthReport'>;
  navigation: AppStackScreenProps<'MonthReport'>['navigation'];
}

export const MonthReportShowScreen = (props: MonthReportShowScreenProps) => {
  const id = props.route.params.id;
  const t = useT('screens', { keyPrefix: 'CashPointClosing' });

  const { fetchMonthReport } = useMonthReportQueries(id);

  return (
    <QuerySuspense
      query={fetchMonthReport}
      onSuccess={monthReport => (
        <ScreenContainer
          title={monthReport.title}
          containerStyle={tw`bg-white`}
          headerWrapperStyle={tw`w-full m-auto max-w-[800px] justify-between m-auto`}
          scrollable={true}
          onBackPress={() => props.navigation.navigate('MonthReports')}>
          <View style={tw`w-full m-auto max-w-[800px]`}>
            <ReportStatGroup>
              {/* Creation Detail */}
              <View style={tw`flex-row`}>
                <Text style={tw`text-base`}>
                  {format(monthReport.created_at, 'dd.MM.yyyy - HH:mm')}
                </Text>
                <Text style={tw`text-base`}> - </Text>
                <Text style={tw`text-base text-success`}>
                  {monthReport.cashier.name}
                </Text>
              </View>

              {/* Month name */}
              <View style={tw`flex-row`}>
                <Text style={tw`text-base`}>{monthReport.month_name}</Text>
              </View>
            </ReportStatGroup>

            <ReportStatGroup>
              <ReportStatItem
                title={t('monthRevenue')}
                value={monthReport.month_revenue}
              />
            </ReportStatGroup>

            <ReportCounts stats={monthReport.stats.month_stats} />
            <ReportStatCategoryDetail
              statsCategory={monthReport.stats.month_stats.invoice}
              category="invoice"
            />
            <ReportStatCategoryDetail
              statsCategory={monthReport.stats.month_stats.cancellation}
              category="cancellation"
            />
            <ReportRevenueAfterCancellation
              stat={monthReport.stats.month_stats.summed.sum}
            />
            <ReportDayStats stats={monthReport.stats.day_stats} />
          </View>
        </ScreenContainer>
      )}
    />
  );
};
