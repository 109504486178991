import React from 'react';
import * as Progress from 'react-native-progress';
import { View, ViewStyle } from 'react-native';
import tw from 'twrnc';

interface SplashScreenProps {
  style?: ViewStyle;
}

const SplashScreen = (props: SplashScreenProps) => {
  return (
    <View
      style={[
        tw`flex-1 items-center justify-center absolute inset-0`,
        props.style,
      ]}>
      <View style={tw`gap-y-5`}>
        <Progress.Circle
          progress={0.9}
          size={100}
          indeterminate={true}
          color={tw.color('green-600')}
          borderWidth={4}
          endAngle={0.4}
          thickness={10}
        />
      </View>
    </View>
  );
};

export default SplashScreen;
