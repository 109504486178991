import React from 'react';
import { View, Text } from 'react-native';
import { ReportStatGroup } from '../../report/report-stat-group';
import { CashPointClosing } from '../../../api/types/cash-register/cash-point-closing.types';
import { CashierReport } from '../../../api/types/cash-register/cashier-report.types';
import { format } from 'date-fns';
import { tw } from '../../../utils/tailwind';
import { ReportStatItem } from '../../report/report-stat-item';
import { ReportCounts } from '../../report/report-counts';
import { ReportStatCategoryDetail } from '../../report/report-stat-category-detail';
import { ReportRevenueAfterCancellation } from '../../report/report-revenue-after-cancellation';
import { useT } from '../../../utils/helper';

interface PointClosingStatSummaryProps {
  pointClosing: CashPointClosing | CashierReport;
}

export const PointClosingStatSummary = (
  props: PointClosingStatSummaryProps,
) => {
  const t = useT('screens', { keyPrefix: 'CashPointClosing' });

  return (
    <>
      <ReportStatGroup>
        <View style={tw`flex-row`}>
          <Text style={tw`text-base`}>
            {format(props.pointClosing.business_day, 'dd.MM.yyyy - HH:mm')}
          </Text>
          <Text style={tw`text-base`}> - </Text>
          <Text style={tw`text-base text-success`}>
            {props.pointClosing.created_by_name}
          </Text>
        </View>

        <View style={tw`flex-row`}>
          <Text style={tw`text-base`}>
            {format(props.pointClosing.started_at, 'dd.MM.yyyy/HH:mm')}
          </Text>
          <Text style={tw`text-base`}> - </Text>
          <Text style={tw`text-base`}>
            {format(props.pointClosing.ended_at, 'dd.MM.yyyy/HH:mm')}
          </Text>
        </View>
      </ReportStatGroup>

      <ReportStatGroup>
        <ReportStatItem
          title={t('dayRevenue')}
          value={props.pointClosing.day_revenue}
        />
        <ReportStatItem
          title={t('monthRevenue')}
          value={props.pointClosing.month_revenue}
        />
        <ReportStatItem
          title={t('yearRevenue')}
          value={props.pointClosing.year_revenue}
        />
      </ReportStatGroup>

      <ReportCounts stats={props.pointClosing.stats} />
      <ReportStatCategoryDetail
        statsCategory={props.pointClosing.stats.invoice}
        category="invoice"
      />
      <ReportStatCategoryDetail
        statsCategory={props.pointClosing.stats.cancellation}
        returnedStats={props.pointClosing.stats.returned}
        category="cancellation"
      />

      <ReportRevenueAfterCancellation
        stat={props.pointClosing.stats.summed.sum}
      />
    </>
  );
};
