import React from 'react';
import { tw } from '../../../utils/tailwind';
import { CashPointClosingListItem } from './cash-point-closing-list-item';
import { useCashPointClosingsQueries } from '../../../api/hooks/cash-register/cash-point-closings/use-cash-point-closings-queries';
import { format } from 'date-fns';
import { STANDARD_DATE_FORMAT } from '../../../utils/helper';
import { FlatListPagination } from '../../../components/flat-list-pagination';

interface CashPointClosingListProps {
  date: Date | null;
}

export const CashPointClosingList = (props: CashPointClosingListProps) => {
  // Queries
  const { fetchCashPointClosings } = useCashPointClosingsQueries({
    q: {
      business_day_eq: props.date
        ? format(props.date, STANDARD_DATE_FORMAT)
        : null,
    },
  });

  return (
    <FlatListPagination
      query={fetchCashPointClosings}
      refreshing={fetchCashPointClosings.isRefetching}
      onRefresh={fetchCashPointClosings.refetch}
      collectionName="cash_point_closings"
      contentContainerStyle={tw`gap-y-1.5`}
      keyExtractor={item => item.id.toString()}
      renderItem={({ item }) => (
        <CashPointClosingListItem key={item.id} cashPointClosing={item} />
      )}
    />
  );
};
