import React from 'react';
import { tw } from '../../../utils/tailwind';
import { CashierReportListItem } from './cashier-report-list-item';
import { useCashierReportsQueries } from '../../../api/hooks/cash-register/cashier-reports/use-cashier-reports-queries';
import { FlatListPagination } from '../../../components/flat-list-pagination';

interface CashierReportListProps {
  cashierId?: number;
}

export const CashierReportList = (props: CashierReportListProps) => {
  // Queries
  const { fetchCashierReports } = useCashierReportsQueries({
    q: {
      cashier_id_eq: props.cashierId,
    },
  });

  return (
    <FlatListPagination
      query={fetchCashierReports}
      refreshing={fetchCashierReports.isRefetching}
      onRefresh={fetchCashierReports.refetch}
      collectionName="cashier_reports"
      contentContainerStyle={tw`gap-y-1.5`}
      keyExtractor={item => item.id.toString()}
      renderItem={({ item }) => (
        <CashierReportListItem key={item.id} cashierReport={item} />
      )}
    />
  );
};
