import React from 'react';
import { View, Text } from 'react-native';
import { tw } from '../../utils/tailwind';

interface ReportStatGroupProps {
  title?: string;
  children: React.ReactNode;
}

export const ReportStatGroup = (props: ReportStatGroupProps) => {
  return (
    <View style={tw`border-b-2 border-gray-100 py-5 gap-y-3`}>
      {props.title ? (
        <Text style={tw`font-medium text-lg `}>{props.title}</Text>
      ) : null}
      {props.children}
    </View>
  );
};
