import request from '../../../request';
import { useInfiniteQuery } from '@tanstack/react-query';
import {
  YearReportsParams,
  YearReportsResponse,
} from '../../../types/cash-register/year-report.types';

export const useYearReportsQueries = (params: YearReportsParams) => {
  const fetchYearReports = useInfiniteQuery(
    ['yearReports', params],
    async ({ pageParam }) =>
      request({
        method: 'get',
        url: '/store/year_reports.json',
        params: { ...params, page: pageParam },
      }) as Promise<YearReportsResponse>,
    {
      getNextPageParam: (lastPage, _pages) => {
        // Return the next page number if there are more pages
        return lastPage.next_page || false;
      },
    },
  );

  return { fetchYearReports };
};
