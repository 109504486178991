import React, { useState } from 'react';
import { TouchableOpacity, Text, View } from 'react-native';
import { tw } from '../../../utils/tailwind';
import { StoreTable as StoreTableType } from '../../../api/types/cash-register/store-table-types';
import { formatCurrency, twCb } from '../../../utils/helper';
import { ModalStoreTableInfo } from '../../../components/cash-register/modals/modal-store-table-info';

interface StoreTableProps {
  storeTable: StoreTableType;
}

export const StoreTable = (props: StoreTableProps) => {
  const [isModalInfoVisible, setIsModalInfoVisible] = useState(false);

  const hasOrderSession = props.storeTable.order_session;
  const textStyle = twCb(
    'text-xl font-medium leading-none',
    hasOrderSession ? 'text-white' : 'text-gray-800',
  );

  const tableBackgroundColor = () => {
    if (props.storeTable.order_session?.pending_invoice?.id) {
      return 'bg-warning';
    } else if (hasOrderSession) {
      return 'bg-success';
    } else {
      return 'bg-gray-100';
    }
  };

  const hashPendingInvoice = props.storeTable.order_session?.pending_invoice;
  const total = hashPendingInvoice
    ? hashPendingInvoice.total_unpaid
    : props.storeTable?.order_session?.total;

  return (
    <View>
      {isModalInfoVisible ? (
        <ModalStoreTableInfo
          isVisible={isModalInfoVisible}
          onModalHide={() => setIsModalInfoVisible(false)}
          storeTable={props.storeTable}
        />
      ) : null}

      <TouchableOpacity
        key={props.storeTable.id}
        onPress={() => setIsModalInfoVisible(true)}
        activeOpacity={1}
        style={tw`${twCb(
          'justify-center items-center md:min-w-[140px] md:min-h-[140px] min-w-[128px] min-h-[128px] rounded-2xl gap-y-2.5 p-3',
          tableBackgroundColor(),
        )}`}>
        <Text style={tw`${textStyle}`}>{props.storeTable.name}</Text>
        {total ? (
          <Text style={tw`${textStyle}`}>{formatCurrency(total)}</Text>
        ) : null}
      </TouchableOpacity>
    </View>
  );
};
